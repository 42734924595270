import styled from "styled-components";

export const Container = styled.div`
    height: 61px;
    .container-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;

        button {
            height: 100%;
            width: 40px;
            text-align:left;
            font-weight: 500;
            font-family: "Oxanium";
            font-size:14px;
            color: black !important;
            
            &.right{
                text-align:right;
            }
            img {
                margin-right:10px;
            }
        }
    }

    .container-passos {
        display: flex;
        justify-content: space-between;
        margin-top: -1px;
        div {
            display: block;
            height: 1px;
            width: 100%;
            margin-right: 4px;
            border-bottom: 1px solid #e1e2e3;
            &.active {
                border-bottom: 1px solid
                    ${(props) => props.theme.colors.secondary};
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }



    .noTitle{
        height: 47px;
        margin:0;
    }
`;
