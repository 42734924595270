import Table, { Pagination } from "../../../Table";
import { Loader } from "../../../../Loader";
import { useWhiteList } from "../../../../../Hook/system/useWhiteList";

import { Data } from "../../config";

import imgLixeira from "../../../../../assets/img/lixeira-white-list.svg";
import imgEditar from "../../../../../assets/img/editar.svg";
import imgCameraVerde from "../../../../../assets/img/camera-verde.svg";
import { formatDateSystem, formataCPF } from "../../../../../util/helper";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";

export function AprovadosWhiteList() {
    const { loading, whiteLists, setExcluirItem, pagination, getData } =
        useWhiteList();

    const rows: Data[] = whiteLists?.map((list) => {
        const file = JSON.parse(list?.file || "{}") as any;

        return {
            id: list.id + "",
            approved_user: list?.approved_user?.name || "",
            reproved_user: list?.reproved_user?.name || "",
            cpf: list.document ? formataCPF(list.document) : "",
            nome: list?.name || "",
            data_inclusao: formatDateSystem(list.created_at),
            data_validacao: formatDateSystem(list.validated_at || ""),
            incluido_por: list?.user?.name || "",
            ver_documento: file?.document || "",
            ver_documento_verso: file?.document_back || "",
            reproved_reason: list?.reproved_reason || "",
            selfie: file?.selfie || "",
            excluir: "",
        };
    });

    const collumns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
        },
        {
            field: "cpf",
            headerName: "CPF",
            width: 150,
        },
        {
            field: "nome",
            headerName: "NOME",
            width: 150,
        },
        {
            field: "data_inclusao",
            headerName: "DATA DE INCLUSÃO",
            width: 150,
        },

        {
            field: "approved_user",
            headerName: "VALIDAÇÃO",
            width: 150,
        },

        {
            field: "data_validacao",
            headerName: "DATA DA VALIDAÇÃO",
            width: 150,
        },

        {
            field: "ver_documento",
            headerName: "VER DOCUMENTO / FRENTE",
            width: 200,
            renderCell: ({ row }: GridRenderCellParams) => {
                if (!row.ver_documento) return <></>;

                return (
                    <button
                        onClick={() => window.open(row.ver_documento, "_blank")}
                    >
                        <img src={imgEditar} alt="" />
                    </button>
                );
            },
        },
        {
            field: "ver_documento_verso",
            headerName: "VER DOCUMENTO / VERSO",
            width: 200,
            renderCell: ({ row }: GridRenderCellParams) => {
                if (!row.ver_documento_verso) return <></>;

                return (
                    <button
                        onClick={() =>
                            window.open(row.ver_documento_verso, "_blank")
                        }
                    >
                        <img src={imgEditar} alt="" />
                    </button>
                );
            },
        },

        {
            field: "selfie",
            headerName: "VER SELFIE",
            width: 200,
            renderCell: ({ row }: GridRenderCellParams) => {
                if (!row.selfie) return <></>;

                return (
                    <button
                        onClick={() =>
                            window.open(row.selfie, "_blank")
                        }
                    >
                        <img src={imgCameraVerde} alt="" />
                    </button>
                );
            },
        },

        {
            field: "excluir",
            headerName: "EXCLUIR",
            flex: 1,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() =>
                            setExcluirItem({
                                active: true,
                                id: row.id,
                            })
                        }
                    >
                        <img src={imgLixeira} alt="" />
                    </button>
                );
            },
        },
    ];

    return (
        <>
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <Table
                    columns={collumns}
                    rows={rows ? rows : []}
                    nameCSV="ADICIONAR-CAMPANHA"
                />
            )}

            {pagination?.last_page > 1 ? (
                <Pagination
                    total={pagination?.last_page}
                    currentPage={async (page) => {
                        await getData(page);
                    }}
                />
            ) : null}
        </>
    );
}
