import styled from "styled-components";
import { Container as DefaultHeader } from "../Header/styles";
import imgLupa from "../../../assets/img/lupa.svg";

export const Container = styled.div`

    position: relative;
    .container-table {
        min-height: calc(90vh - 100px);
    }

    .MuiDataGrid-row.Mui-selected.MuiDataGrid-row--lastVisible,
    .MuiDataGrid-row.MuiDataGrid-row--lastVisible{
        background-color:#5DB356;

        &:hover{
            background-color:#5DB356;
        }

        .MuiDataGrid-cellContent{
            color:#fff;
            font-weight:700;
        }
    }

    .MuiDataGrid-columnHeadersInner{
        .MuiDataGrid-columnHeader:nth-child(n+3){
            .MuiDataGrid-columnHeaderDraggableContainer{
                .MuiDataGrid-columnHeaderTitleContainer{
                    justify-content:center;
                    display:flex;
                }
            }
        }
    }

    .MuiDataGrid-row{
        .MuiDataGrid-cell:nth-child(2){
            text-transform:none;
        }
    }
`;

export const HeaderStyles = styled.div`
    display: flex;
    border-bottom: 1px solid #e1e2e3;
    justify-content: space-between;
    ${DefaultHeader} {
        border-bottom: unset;
    }
    .busca {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            position: relative;
            width: auto;
            margin: 0px;
            margin-left: 11px;
        }
        .container-input-system {
            margin-bottom: 0px;
            width: 532px;
            position: relative;
            input {
                padding-left: 45px;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 13px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${imgLupa}) no-repeat;
                width: 19px;
                height: 17px;
                z-index: 20;
                transition: opacity 0.5s;
            }
        }

        img{
            cursor:pointer;
            position: absolute;
            right: 15px;
        }
    }
`;

export const Actions = styled.div`
    display:flex;
    justify-content:space-between;

    .data-container{
        display:flex;
        width:100%;
        max-width:532px;
        margin-top:20px;
        gap:14px;

        div{
            display:flex;
            gap:7px;
        
            div{
                display:flex;
                align-items:center;
                gap:7px;

                font-size:14px;
                color:${(props) => props.theme.colors.secondary};
                font-family: Lato;
            }

            .date-picker-start {
                .MuiInputBase-root{
                    width:110px;
                    height:26px;
                    .MuiInputBase-input{
                        &.MuiOutlinedInput-input{
                            border:1px solid ${(props) => props.theme.colors.secondary};
                            padding:2px 2px 2px 0;
                            border-radius:5px;
                            color:${(props) => props.theme.colors.primaryDark};
                            font-family: Lato;
                            font-size:14px;
                            text-align:center;

                            &::placeholder {
                                color: ${(props) => props.theme.colors.secondary};
                                font-size:14px;
                                opacity:1;
                            }
                        }
                    }
                }
            }
        }

        .lupa{
            display:flex;
            align-items:center;
            justify-content:center;
            border-radius:5px;
            border:none;
            height:26px;
            width:26px;
            background-color:${(props) => props.theme.colors.secondary};

            img{
                width:16px;
            }  
        }

    }

    .export{
        display:flex;
        align-items:center;
        gap:7px;
        margin-top:20px;
        cursor:pointer;
        span{
            font-size:14px;
            color:${(props) => props.theme.colors.secondary};
            font-family: Lato;
        }
    }

`;
