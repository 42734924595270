import { Text } from "alisson-application";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { UseModal } from "../../Hook/Usemodal";
import { getCampanha, podesolicitarCartao } from "../../util/helper";
import { Button } from "../Mobile/Button";
import { CheckBox } from "../Mobile/CheckBox";
import imgSuccess from "../../assets/img/sucesso.svg";
import { UseCartoes } from "../../Hook/user/UseCartaoes";
import { useUser } from "../../Hook/user/UseUser";
import { Container } from "./styles";

export function DiferencaCartoes() {
    const { handleClose, state, setState } = UseModal();
    const [checked, setChecked] = useState(false);
    const { cartoes } = UseCartoes();
    const { user } = useUser();
    const history = useHistory();
    const width = window.screen.width;

    function handleChecked() {
        const options = podesolicitarCartao([getCampanha()], cartoes);
        const optionsLength = options.length || 0;
        const value = options[0]?.value || "";
        const saldo = parseFloat(user.saldo + "" || "0");

        if (optionsLength === 1 && value === "cartao_fisico" && saldo < 30) {
            setState({
                ...state,
                diferencaCartoes: false,
                saldoInsuficiente: true,
            });
            return;
        }

        setState({
            ...state,
            diferencaCartoes: false,
            novoCartao: true,
        });

        if (width <= 480) {
            history.push({
                pathname: "novo-cartao",
                state: {
                    valid: true,
                },
            });
        } else {
            setChecked(false);
        }
    }

    return (
        <Container>
            <img className="img-close" src={imgSuccess} alt="" />

            <Text as="h1">
                Entenda Quais Cartões <br />
                Você Pode Pedir
            </Text>

            <div className="container-itens">
                {getCampanha().produtos?.map((produto, key) => {
                    const count = cartoes.reduce((acc, item) => {
                        if (item.tipo === produto.produto.tipo) {
                            return acc + 1;
                        }
                        return acc;
                    }, 0);

                    if (count >= produto.lim_quantidade)
                        return <div key={produto.id} />;

                    // if (produto?.produto?.tipo === "cartao_avulso")
                    //     return <div key={produto.id} />;


                    const names = {
                        cartao_virtual: "Cartão Virtual",
                        cartao_fisico: "Cartão Físico Personalizado",
                        cartao_avulso: "Cartão Físico Avulso",
                    }

                    const descripions = {
                        cartao_virtual: "Estará disponível imediatamente em “Meus Cartões” e pode ser utilizado em lojas virtuais e APPs que aceitem a bandeira Mastercard.",
                        cartao_fisico: "Será entregue no endereço cadastrado em até 15 dias úteis  e pode ser usado em quaisquer lojas (Físicas, Virtuais e/ou APPs) que aceitem a bandeira Mastercard.",
                        cartao_avulso: "Foi entregue no seu local de trabalho pelo seu gestor e já está em suas mãos para ser usado em quaisquer lojas (Físicas, Virtuais e/ou APPs) que aceitem a bandeira Mastercard.",
                    }

                    return (
                        <div key={produto.id} className="container-descricao">
                            {produto.produto.tipo &&
                                <>
                                    <Text as="h3" >
                                        {names[produto.produto.tipo as keyof typeof names] || "Cartão"}
                                    </Text>
                                    <div className="text-item">
                                        {descripions[produto.produto.tipo as keyof typeof names] || ""}
                                    </div>
                                </>
                            }
                        </div>
                    );
                })}
            </div>

            <CheckBox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                label="<h5>Li e comprendi as diferenças entre as <br /> opções de cartões.</h5>"
            />

            <Button disabled={!checked} onClick={() => handleChecked()}>
                Avançar
            </Button>
            <button onClick={() => handleClose("diferencaCartoes")}>
                <span>
                    Cancelar
                </span>
            </button>
        </Container>
    );
}
