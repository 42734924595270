import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 15px;
    padding-top: 35px;
    position: relative;



    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
        color: ${({ theme }) => theme.colors.secondary};
        margin-top: 22px;
        margin-bottom: 21px;
    }

    h5 {
        margin-bottom: 48px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: ${(props) => props.theme.colors.textSubtle};
    }

    .container-itens {
        margin-bottom: 20px;
        padding-bottom:20px;
        overflow: auto;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
        }
        
        @media (min-width: 769px) {
            height: 260px;
        }

        @media (min-width: 1550px) {
            height: auto;
        }

        .container-descricao {
            margin-bottom: 32px;
            &:last-child {
                margin-bottom: 0;
            }
            h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 100%;
                color: ${(props) => props.theme.colors.textSubtle};
                text-align: center;
            }
            .text-item {
                margin:3px 25px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 120%;
                text-align: center;
                color: ${(props) => props.theme.colors.primaryDark};
            }
        }
    }

    button{
        width: 285px;
        height: 2.5rem;
        font-weight: 700;
        letter-spacing: 0;
        text-transform: none;
        span{
            font-weight: 400;
            color: ${(props) => props.theme.colors.textSubtle};
        }
    }

    .container-input {
        width: 100%;
    }
`;
