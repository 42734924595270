import { memo } from "react";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import Table, { Pagination } from "../../../../Table";

import { formatDateIsoSystem } from "../../../../../../util/helper";

import { useInfra } from "../Hook";
import { Data } from "./config";
import imgSetaDir from "../../../../../../assets/img/seta-dir-sistema.svg";
import { TableStyles } from "./styles";
import { Loader } from "../../../../../Loader";
import { When } from "../../../../../when";
import { Filter } from "./filter";

function TableCarga() {
    const { cargas, loading, configCargas, getCarga, searchCarga } = useInfra();
    async function pagination(page: number) {
        await getCarga(page);
    }

    const rows: Data[] = cargas?.map((carga) => {
        return {
            id: parseInt(carga.id.toString()),
            titulo: carga.nome.trim(),
            data_hora: formatDateIsoSystem(carga.created_at),
            aprovado: formatDateIsoSystem(carga.updated_at),
            criado_por: carga.user.name.trim(),
            qtd_user: carga.total_itens_users,
            next: "",
            file: carga.file,
            job: carga.job,
            observacao: carga.observacao,
            contato: carga.cliente_area_contato?.nome || "",
        };
    });

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 50,
        },
        {
            field: "titulo",
            headerName: "Título",
            width: 200,
        },
        {
            field: "data_hora",
            headerName: "Data/Hora",
            width: 200,
        },
        {
            field: "criado_por",
            headerName: "Criado por",
            width: 100,
        },
        {
            field: "aprovado",
            headerName: "Data de aprovação",
            width: 200,
        },
        {
            field: "qtd_user",
            headerName: "Qtd. Usuários",
            width: 10,
        },
        {
            field: "contato",
            headerName: "CONTATO",
            width: 200,
        },
        {
            field: "observacao",
            headerName: "OBSERVAÇÃO",
            width: 150,
        },
        {
            field: "job",
            headerName: "JOB",
            width: 120,
        },
        {
            field: "next",
            headerName: "",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    row.file && (
                        <button
                            onClick={() => (window.location.href = row.file)}
                        >
                            <img src={imgSetaDir} alt="" />
                        </button>
                    )
                );
            },
            width: 14,
        },
    ];

    return (
        <TableStyles>
            <When expr={loading}>
                <div className="container-loading">
                    <Loader />
                </div>
            </When>
            <Filter searchFetchData={searchCarga} />
            <When expr={!loading}>
                <Table
                    rows={rows ? rows : []}
                    columns={columns}
                    nameCSV="USUÁRIOS"
                />
            </When>

            {configCargas?.last_page > 1 ? (
                <Pagination
                    total={configCargas?.last_page}
                    currentPage={(page) => {
                        pagination(page);
                    }}
                />
            ) : null}
        </TableStyles>
    );
}

export const TableMemorized = memo(TableCarga);
