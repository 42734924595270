import { useState, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import Table, { Pagination } from "../../../../Table";
import {
    formatDateIsoSystem,
    formatCurrentMoney,
} from "../../../../../../util/helper";

import { useCartaoDeCredito, carga } from "../Hook";
import { Data } from "./config";
import imgSetaDir from "../../../../../../assets/img/seta-dir-sistema.svg";
import { TableStyles } from "./styles";
import { Loader } from "../../../../../Loader";
import { PaginationConfig } from "../../../../../../Hook/system/config";
import { useCampanha } from "../../../../../../Hook/system/useCampanha";
import { Busca } from "./busca";

function TableCarga() {
    const { getCarga, searchCarga } = useCartaoDeCredito();
    const { campanhaById } = useCampanha();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [cargas, setCargas] = useState<carga[]>([]);
    const [config, setConfig] = useState<PaginationConfig>(
        {} as PaginationConfig
    );

    async function pagination(page: number) {
        setLoading(true);
        try {
            const response = await getCarga(page);
            const { data, ...resto } = response;

            setConfig(resto);
            setCargas(response.data);
        } catch (error) {
            console.log(error);
            setCargas([]);
            setConfig({} as PaginationConfig);
        }
        setLoading(false);
    }

    async function serach(value: string) {
        try {
            setLoading(true);
            const reponse = await searchCarga(value);
            const { data, ...resto } = reponse;
            setConfig(resto);
            setCargas(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setCargas(cargas);
        }
    }

    const rows: Data[] = cargas?.map((carga) => {
        return {
            id: carga.id,
            titulo: carga.nome.trim(),
            data_hora: formatDateIsoSystem(carga.created_at),
            criado_por: carga.user.name.trim(),
            apr_por: carga?.approve_user?.name.trim(),
            aprovado: formatDateIsoSystem(carga.updated_at),
            valor:
                "R$ " +
                formatCurrentMoney(
                    parseFloat(
                        carga.total_noname_credito_sum
                            ? carga.total_noname_credito_sum
                            : "0"
                    )
                ),
            next: "",
            observacao: carga.observacao,
            job: carga?.job,
            contato: carga.cliente_area_contato?.nome || "",
        };
    });

    const column: GridColumns = [
        {
            field: "id",
            headerName: "id",
            width: 50,
        },
        {
            field: "titulo",
            headerName: "Título",
            width: 172,
        },
        {
            field: "data_hora",
            headerName: "Data/Hora",
            width: 172,
        },
        {
            field: "criado_por",
            headerName: "Criado por",
            width: 172,
        },
        {
            field: "apr_por",
            headerName: "Aprovado por",
            width: 120,
        },
        {
            field: "aprovado",
            headerName: "Data de aprovação",
            width: 200,
        },
        {
            field: "valor",
            headerName: "Valor",
            width: 120,
        },
        {
            field: "contato",
            headerName: "CONTATO",
            width: 120,
        },
        {
            field: "job",
            headerName: "JOB",
            width: 100,
        },
        {
            field: "observacao",
            headerName: "OBSERVAÇÃO",
            width: 100,
        },
        {
            field: "next",
            headerName: "",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() =>
                            history.push({
                                pathname: `/sistema/campanha/carga/${row.id}/detalhe`,
                                state: {
                                    id: row.id,
                                    type: "nologs",
                                    carga: "cartoes",
                                    campanha: campanhaById.nome,
                                },
                            })
                        }
                    >
                        <img src={imgSetaDir} alt="" />
                    </button>
                );
            },
            hideSortIcons: true,
            disableColumnMenu: true,
        },
    ];

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const reponse = await getCarga();
            const { data, ...resto } = reponse;
            setConfig(resto);
            setCargas(data);
            setLoading(false);
        }

        fetchData();
    }, [getCarga]);

    return (
        <TableStyles>
            <Busca serach={serach} />
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <Table
                    rows={rows ? rows : []}
                    columns={column}
                    nameCSV="CARTAO-DE-CREDITO"
                />
            )}
            {config?.last_page > 1 ? (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page);
                    }}
                />
            ) : null}
        </TableStyles>
    );
}

export const TableCargaMemorized = memo(TableCarga);
