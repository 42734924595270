import { useContext, createContext, useState} from "react";
import { api } from "../../services";
import { toast } from "react-toastify";

export type Item ={
    tipo: string
    total: number
    valorUnitario: number
    valorTotal: number
}

export type ItemComInvestimento = Item & {tipoInvestimento: string}

interface Data {
    data: Item[];
}

interface useRelatorioProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseRelatorio {
    fetch(startDate:string, endDate:string): Promise<Data | undefined>;
    data: ItemComInvestimento[];
    loading: boolean;
}

const UseRelatorioContext = createContext<UseRelatorio>({} as UseRelatorio);

export function RelatorioProviderIdWall(props: useRelatorioProviderProps) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Data>({} as Data);

    async function fetch(startDate:string, endDate:string) {
        if(!startDate || !endDate){
            toast.error("Data inicial e final são obrigatórias")
            return;
        }
        
        try {
            setLoading(true);
            const { data } = await api.post<Data>(
                `/reports/idwall-acessos`,
                {
                    startDate: startDate+" 00:00:00",
                    endDate: endDate+" 00:00:00",
                }
            );
            setData(data);
            setLoading(false);
            return data;
        } catch (error:any) {
            setData({ data: [] });
            setLoading(false);
            toast.error(error.response.data.message);
        }
    }

    function formatedData(data: Item[]): any {
        if (!data) return []
        
        const formated = data.reduce((accumulator:ItemComInvestimento[], item) => {
            accumulator.push({
                ...item,
                tipoInvestimento:item?.tipo.toLowerCase().trim() === "mensalidade" || item.tipo.toLowerCase().trim() === "licença de uso sdk" ? "Fixo" : "Variável",}
            )
            return accumulator
        }, [])
          
      return formated
    }

    return (
        <UseRelatorioContext.Provider
            value={{
                fetch,
                data: formatedData(data.data),
                loading,
            }}
        >
            {children}
        </UseRelatorioContext.Provider>
    );
}

export function useRelatorioIdWall() {
    const context = useContext(UseRelatorioContext);
    return context;
}