import { useEffect, useState } from "react";
import { Data, Item } from "./config";
import { useRelatorioIdWall } from "../../../Hook/system/useRelatorioIdWall";

import Table from "../Table";
import { Header } from "./header";
import { Container, Actions } from "./styles";
import { Loader } from "../../Loader";
import { GridColumns } from "@mui/x-data-grid-pro";

import calendario from "../../../assets/img/calendario.svg";
import lupa from "../../../assets/img/lupa-branca.svg";
import download from "../../../assets/img/dowload.svg";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from 'date-fns/locale/pt-BR';
import { formatCurrentMoney } from "../../../util/helper";

export function RelatorioIdWall() {
    const totalWidth = window.screen.width < 1200 ? 800 : window.screen.width - 300;
    const width = totalWidth / 5;
    const [search, setSearch] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filtredData, setFiltredData] = useState<Data>([]);

    const {
        fetch,
        data,
        loading,
    } = useRelatorioIdWall();

    useEffect(() => {
        if (search === "") {
            setFiltredData(data);
            return
        }

        const filteredData = data.filter((row) => {
            const lowerCaseSearch = search.toLowerCase().trim();

            if (row.tipoInvestimento.toLowerCase().includes(lowerCaseSearch)
                || row.tipo.toLowerCase().includes(lowerCaseSearch)
                || formatCurrentMoney(row.valorUnitario).includes(lowerCaseSearch)
                || (row.total && row.total.toString().includes(lowerCaseSearch))
                || formatCurrentMoney(row.valorTotal).includes(lowerCaseSearch)
            ) return row
            return null
        });

        setFiltredData(filteredData);

    }, [data, search]);


    const dados = filtredData?.map((item: Item, index) => {
        return {
            id: index.toString(),
            tipoInvestimento: item?.tipoInvestimento,
            tipo: item?.tipo,
            valorUnitario: "R$ " + formatCurrentMoney(+item?.valorUnitario),
            total: item?.total,
            valorTotal: "R$ " + formatCurrentMoney(+item?.valorTotal),
        };
    }) || [];


    const totais = () => {
        let totalQtd = 0;
        let totalValue = 0;

        if (filtredData.length > 0) {
            for (const item of filtredData) {
                totalQtd += item.total || 0;
                const totalValueQG = item.valorTotal || "0";
                totalValue += +totalValueQG;
            }

            return [{
                id: "totais",
                tipoInvestimento: "",
                tipo: "",
                valorUnitario: "Total",
                total: totalQtd,
                valorTotal: "R$ " + formatCurrentMoney(totalValue)
            }]
        }

        return []
    }



    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 0,
        },
        {
            field: "tipoInvestimento",
            headerName: "TIPO INVESTIMENTO",
            width,
        },
        {
            field: "tipo",
            headerName: "ITEM TIPOS DE CONSULTAS",
            width,
        },
        {
            field: "valorUnitario",
            headerName: "VALOR UNITÁRIO",
            width,
            align: "center"
        },
        {
            field: "total",
            headerName: "QUANTIDADE YETZ",
            width,
            align: "center"
        },
        {
            field: "valorTotal",
            headerName: "VALOR TOTAL",
            width,
            align: "center"
        },
    ];

    function handleStartDate(e: string) {

        setStartDate(e);

        if (endDate === "") return;

        const selectedStartDate = new Date(e);
        const selectedEndDate = new Date(endDate);

        if (selectedStartDate > selectedEndDate) {
            setEndDate(e);
        }
    }

    function handleEndDate(e: string) {
        setEndDate(e);

        if (startDate === "") return;

        const selectedStartDate = new Date(startDate);
        const selectedEndDate = new Date(e);

        if (selectedStartDate > selectedEndDate) {
            setStartDate(e);
        }
    }

    function handleFetch() {
        setSearch("")
        setFiltredData([])
        const formatStartDate = new Date(startDate).toLocaleDateString('en-CA');
        const formatEndtDate = new Date(endDate).toLocaleDateString('en-CA');
        fetch(formatStartDate, formatEndtDate);
    }


    async function exportReport() {


        const currentDate = new Date();
        let outputFilename = `Relatorio-IdWall  ${currentDate.getDate().toString().padStart(2, '0')}-${currentDate.getMonth().toString().padStart(2, '0')}-${currentDate.getFullYear()}.csv` as string;

        const startDateToCVS = new Date(startDate);
        const endDateToCVS = new Date(endDate);

        const researchDate = {
            "id": "date",
            "tipoInvestimento": `Gerada em ${currentDate.getDate().toString().padStart(2, '0')}-${currentDate.getMonth().toString().padStart(2, '0')}-${currentDate.getFullYear()}`.replaceAll("-", "/"),
            "tipo": `Período de ${startDateToCVS.getDate().toString().padStart(2, '0')}-${startDateToCVS.getMonth().toString().padStart(2, '0')}-${startDateToCVS.getFullYear()} a ${endDateToCVS.getDate().toString().padStart(2, '0')}-${endDateToCVS.getMonth().toString().padStart(2, '0')}-${endDateToCVS.getFullYear()}`.replaceAll("-", "/"),
            "valorUnitario": null,
            "total": null,
            "valorTotal": null
        }

        const empty = {
            "id": "empty",
            "tipoInvestimento": null,
            "tipo": null,
            "valorUnitario": null,
            "total": null,
            "valorTotal": null
        }

        const header = {
            "id": "",
            "tipoInvestimento": "Tipo de Investimento",
            "tipo": "Item Tipos de Consultas",
            "valorUnitario": "Total Unitário",
            "total": "Quantidade Yetz",
            "valorTotal": "Valor Total"
        }

        const withHeaderAndFooter = [researchDate, empty, header, ...dados, empty, ...totais()]

        const asRow = withHeaderAndFooter.map((item) => {

            const row = [
                item.tipoInvestimento || "",
                item.tipo || "",
                item.valorUnitario || "",
                item.total || "",
                item.valorTotal || ""
            ]
            return row
        })

        // Função para converter o array em CSV
        function convertToCSV(array: any[]) {
            return array.map(row => row.join(";")).join("\n");
        }

        // Função para baixar o arquivo CSV
        function downloadCSV(csvContent: BlobPart, filename:string) {
            const bom = '\uFEFF';
            const blob = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        // Converter o array asRow para CSV
        const csvContent = convertToCSV(asRow);

        // Baixar o arquivo CSV
        downloadCSV(csvContent, outputFilename);
    }

    return (
        <Container>
            <Header setSearch={setSearch} search={search} />
            <Actions>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                    <div className="data-container">
                        <div>
                            <div>
                                <img src={calendario} alt="" /> <p>INÍCIO</p>
                            </div>
                            <MobileDatePicker
                                showToolbar={false}
                                componentsProps={{
                                    actionBar: {
                                        actions: [],
                                    },
                                }}
                                closeOnSelect
                                className="date-picker-start"
                                inputFormat="dd/MM/yyyy"
                                value={startDate}
                                onChange={(newValue) => newValue && handleStartDate(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="DD/MM/AAAA"
                                        error={false}
                                    />

                                )}

                            />
                        </div>
                        <div>
                            <div>
                                <img src={calendario} alt="" /> <p>FIM</p>
                            </div>
                            <MobileDatePicker
                                showToolbar={false}
                                componentsProps={{
                                    actionBar: {
                                        actions: [],
                                    },
                                }}
                                closeOnSelect
                                className="date-picker-start"
                                inputFormat="dd/MM/yyyy"
                                value={endDate}
                                onChange={(newValue) => newValue && handleEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} placeholder="DD/MM/AAAA" error={false} />}
                            />
                        </div>

                        <button disabled={startDate === "" || endDate === ""} onClick={handleFetch}>
                            <span className="lupa"><img src={lupa} alt="" /></span>
                        </button>
                    </div>
                </LocalizationProvider>
                <button disabled={filtredData.length === 0} className="export" onClick={exportReport}>
                    <img src={download} alt="" />
                    <span>EXPORTAR</span>
                </button>

            </Actions>

            <div className="container-table">
                {loading ? (
                    <div className="container-loading">
                        <Loader height={100} width={100} />
                    </div>
                ) : (
                    <>
                        <Table
                            rows={dados.length > 0 ? [...dados, ...totais()] : []}
                            columns={columns}
                            nameCSV="relatório ID Wall"
                        />
                    </>
                )}
            </div>
        </Container>
    );
}
